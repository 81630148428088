import { useState, useEffect } from "react";
import { Tooltip } from "primereact/tooltip";
import { TFunction } from "i18next";
import { Disclosure } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { getCompany } from "core";
import { UseMutateFunction, useMutation, useQuery } from "react-query";
import {
  Envelope,
  UserDocument,
  UserDocumentStatus,
  documentLogicQueries,
  documentLogicMutations,
} from "./documents.logic";
import { Button, Container, Icon, Modal, Typo, LoadingV2 } from "components";
import { GedDialogAdd } from "./GedDialogAdd";

export type DialogDocumentAdd = {
  envelopeID?: string;
  category: string;
};

export function Documents() {
  // States
  const [view, setView] = useState<string>();
  const [showDialogDocumentAdd, setShowDialogDocumentAdd] =
    useState<DialogDocumentAdd>();
  const [category, setCategory] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  // Hooks
  const { t } = useTranslation();

  const { data: resultGetCompany, isLoading: isLoadingGetCompany } = useQuery(
    ["company"],
    async () => await getCompany()
  );

  const companyId = resultGetCompany?.getCompany;

  const { data, isLoading, refetch } = useQuery(
    ["documentList", companyId],
    async () => {
      if (!companyId) return null;

      return await documentLogicQueries.documentListQuery(companyId);
    },
    { enabled: !!companyId }
  );

  // mutations
  const { mutate: viewFile } = useMutation(
    documentLogicQueries.documentFileQuery,
    {
      onSuccess: setView,
    }
  );
  const { mutate: handleDigitalDocument } = useMutation(
    documentLogicQueries.documentPortalQuery,
    {
      onSuccess: async (url) => window.open(url, "_blank"),
    }
  );

  const { mutate: documentFileAdd } = useMutation(
    (data: { storageName: string }) =>
      documentLogicMutations.documentFileAdd(
        companyId as string,
        data,
        category as string
      ),
    {
      onSuccess: () => {
        // Close modal
        setShowDialogDocumentAdd(undefined);
        alert("Document ajouté");
        refetch();
      },
    }
  );
  const { mutate: onSubmit } = useMutation(
    documentLogicMutations.documentFileUpload,
    {
      onSuccess: async (data) => {
        if (!file) return;

        try {
          // Upload file to bucket
          const bucketResonse = await fetch(data.url, {
            method: "PUT",
            body: file,
            headers: {
              "x-amz-acl": "public-read",
              "Content-Type": file.type as string,
            },
          });

          if (!bucketResonse.ok)
            throw new Error("Error uploading file to bucket");

          // Send to backend
          documentFileAdd({
            storageName: data.name,
          });
        } catch (error) {
          console.error("Error uploading file", error);
        }
      },
    }
  );

  if (isLoadingGetCompany || isLoading) return <LoadingV2 />;

  return (
    <Container className="rounded-xl py-5 px-6">
      <div className="flex justify-between">
        <Typo
          type="wlc_inter_l"
          children="page.profile.documents.title"
          className="mb-6 text-lg font-bold"
        />
        <div
          className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-blue-800 text-white hover:!bg-blue-900"
          onClick={() => setShowDialogDocumentAdd?.({ category: "LEGAL" })}
        >
          <Tooltip target=".pi-plus" />
          <i
            className="pi pi-plus text-xs"
            style={{ color: "white", fontWeight: "900" }}
            data-pr-position="left"
            data-pr-at="left-15 center"
            data-pr-my="right center"
          ></i>
        </div>
      </div>
      {data?.categories?.map((category) =>
        category !== "ARBITRARY" ? (
          <DocumentCategoryBlock key={category} category={category}>
            <DocumentsTable
              data={
                data?.documents[
                  category.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase()
                ] || []
              }
              t={t}
              viewFile={viewFile}
              handleDigitalDocument={handleDigitalDocument}
            />
          </DocumentCategoryBlock>
        ) : null
      )}
      <DocumentCategoryBlock category={"arbitrary"}>
        <EnvelopTable
          data={data?.documents["arbitrary"] || []}
          t={t}
          viewFile={viewFile}
          handleDigitalDocument={handleDigitalDocument}
        />
      </DocumentCategoryBlock>
      {view && (
        <DocumentViewerModal src={view} open={view !== null} onHide={setView} />
      )}
      {showDialogDocumentAdd && (
        <GedDialogAdd
          visible={!!showDialogDocumentAdd}
          onSubmit={async (res) => {
            setFile(res.file);
            setCategory(res.category);
            onSubmit({
              MIME: res.file?.type as string,
              name: res.file?.name as string,
            });
          }}
          isLoading={false}
          defaultCategory={showDialogDocumentAdd?.category}
          categories={data?.categories ? data?.categories : []}
          setVisible={() => setShowDialogDocumentAdd(undefined)}
        />
      )}
    </Container>
  );
}

interface DocumentsTableProps {
  data: UserDocument[];
  t: TFunction;
  viewFile: UseMutateFunction<string, unknown, string, any>;
  handleDigitalDocument: UseMutateFunction<string, unknown, string, unknown>;
}
function DocumentsTable({
  data: defaultData,
  t,
  viewFile,
  handleDigitalDocument,
}: DocumentsTableProps) {
  const [data, setData] = useState(defaultData);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState<UserDocument | null>(null);
  const sortBy = ({ headerCol }: { headerCol: keyof UserDocument }) => {
    if (!["asc", "desc"].includes(order)) {
      return;
    }

    if (order === "asc") {
      const sorted = [...data].sort((a, b) =>
        (a[headerCol] ?? "") > (b[headerCol] ?? "") ? 1 : -1
      );
      setData(sorted);
      setOrder("desc");
    }

    if (order === "desc") {
      const sorted = [...data].sort((a, b) =>
        (a[headerCol] ?? "") < (b[headerCol] ?? "") ? 1 : -1
      );
      setData(sorted);
      setOrder("asc");
    }
  };

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);
  return (
    <table className="min-w-full divide-y divide-gray-300 border-none">
      <thead className="text-gray-950 bg-slate-50">
        <tr>
          <th
            scope="col"
            className="cursor-pointer rounded-l-xl py-3 pl-4 pr-3 text-left text-sm font-semibold hover:bg-lightblue-50 hover:text-lightblue-1000 sm:pl-3"
            onClick={() => sortBy({ headerCol: "name" })}
          >
            {t("page.profile.documents.documents")}
          </th>
          <th
            scope="col"
            className="w-36 cursor-pointer px-3 py-3 text-left text-sm font-semibold hover:bg-lightblue-50 hover:text-lightblue-1000"
            onClick={() => sortBy({ headerCol: "status" })}
          >
            {t("page.profile.documents.treatment")}
          </th>
          <th
            scope="col"
            className="relative w-28 rounded-r-xl py-3 pl-3 pr-4 sm:pr-3"
          >
            {t("page.profile.documents.actions")}
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody className="mt-3 divide-y divide-gray-200 border border-none bg-white">
        {data.map((document) => (
          <tr key={document.id}>
            <td
              className="cursor-pointer whitespace-nowrap py-_10 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
              onClick={() => viewFile(document.id)}
            >
              <div>{document.name}</div>
              <div>{new Date(document.created).toLocaleDateString()}</div>
            </td>
            <td className="whitespace-nowrap px-3 py-_10 text-sm text-gray-500">
              {document.status === UserDocumentStatus.WAITING && (
                <span className="rounded-xl bg-[#FDC775] px-3 py-1 text-xs font-medium text-white">
                  {t(
                    `page.profile.documents.${document.status?.toLowerCase()}`
                  )}
                </span>
              )}
              {document.status === UserDocumentStatus.SIGNATURE_REQUIRED && (
                <span className="rounded-xl bg-[#FF0000] px-3 py-1 text-xs font-medium text-white">
                  {t(
                    `page.profile.documents.${document.status?.toLowerCase()}`
                  )}
                </span>
              )}
              {document.status === UserDocumentStatus.CONFIRMATION_REQUIRED && (
                <span className="rounded-xl bg-[#FF0000] px-3 py-1 text-xs font-medium text-white">
                  {t(
                    `page.profile.documents.${document.status?.toLowerCase()}`
                  )}
                </span>
              )}
              {document.status === UserDocumentStatus.COMPLETED && (
                <span className="rounded-xl bg-green-600 px-3 py-1 text-xs font-medium text-white">
                  {t(
                    `page.profile.documents.${document.status?.toLowerCase()}`
                  )}
                </span>
              )}
            </td>
            <td className="flex justify-start gap-x-3 py-5 pl-3 pr-4 sm:pr-3">
              <Icon
                type="wc2_search"
                className="cursor-pointer hover:stroke-lightblue-50"
                onClick={() => viewFile(document.id)}
              />
              {document.status !== UserDocumentStatus.COMPLETED && (
                <Icon
                  type="wc2_upload"
                  className="cursor-pointer hover:stroke-lightblue-50"
                  onClick={() => setSelected(document)}
                />
              )}
              {document.status === UserDocumentStatus.SIGNATURE_REQUIRED && (
                <Icon
                  type="signature"
                  className="h-5 w-5 cursor-pointer hover:stroke-lightblue-50"
                  onClick={() => handleDigitalDocument(document.id)}
                />
              )}
            </td>
          </tr>
        ))}
        {selected && (
          <DocumentsModal
            t={t}
            document={selected}
            open={selected !== null}
            onHide={() => setSelected(null)}
          />
        )}
      </tbody>
    </table>
  );
}

function DocumentsModal({
  document,
  open,
  onHide,
  t,
}: {
  document: UserDocument;
  open: boolean;
  onHide: (open: boolean | any) => void;
  t: TFunction;
}) {
  // States
  const [file, setFile] = useState<File | null>(null);

  // Mutations
  const { mutate: onDocumentFileUpdate, isLoading: isUpdateLoading } =
    useMutation(
      (data: { storageName: string }) =>
        documentLogicMutations.documentFileUpdate(document.id, data),
      {
        onSuccess: () => {
          // Close modal
          onHide(null);
        },
      }
    );
  const { mutate: onSubmit, isLoading: isFileLoading } = useMutation(
    documentLogicMutations.documentFileUpload,
    {
      onSuccess: async (data) => {
        if (!file) return;

        try {
          // Upload file to bucket
          const bucketResonse = await fetch(data.url, {
            method: "PUT",
            body: file,
            headers: {
              "x-amz-acl": "public-read",
              "Content-Type": file.type as string,
            },
          });

          if (!bucketResonse.ok)
            throw new Error("Error uploading file to bucket");

          // Send update to backend
          onDocumentFileUpdate({
            storageName: data.name,
          });
        } catch (error) {
          console.error("Error uploading file", error);
        }
      },
    }
  );

  return (
    <Modal setOpen={onHide} open={open}>
      <div>
        <Typo
          type="wlc_inter_m"
          children={document.name}
          className="font-semibold"
        />
        <Typo
          type="wlc_inter_s"
          children="page.profile.documents.addFileDescription"
          className="mt-5 mb-10 font-medium"
        />
        <div className="mx-auto w-80 self-center">
          <div className="mt-2 flex justify-center rounded-lg border-2 border-dashed border-gray-900 px-6 py-10">
            <div className="text-center">
              <Icon
                type="wc2_upload"
                className="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
              <div className="mt-4 mb-2 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative mr-4 cursor-pointer rounded-md bg-primary-1100 px-1 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-secondary-500 focus-within:ring-offset-2 hover:text-primary-400"
                >
                  <span>{t("forms.actions.uploadFile")}</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={(e) => setFile(e.target.files?.[0] || null)}
                  />
                </label>
                <p className="pl-1">
                  {file ? file.name : t("forms.actions.dragAndDrop")}
                </p>
              </div>
              <p className="text-xs leading-5 text-gray-600">
                {t("forms.actions.maxFileSize")}
              </p>
            </div>
          </div>
          <div className="mx-auto my-4 flex justify-center gap-x-4">
            <Button
              label="forms.actions.cancel"
              onClick={onHide}
              className="border-2 border-primary-1000 py-2 px-3 text-primary-1100 hover:border-primary-1100 hover:bg-white"
            />
            <Button
              label="forms.actions.validate"
              disabled={!file || isUpdateLoading || isFileLoading}
              onClick={() =>
                onSubmit({
                  MIME: file?.type as string,
                  name: file?.name as string,
                })
              }
              className="border-2 border-primary-1100 bg-primary-1100 py-2 px-3 text-white hover:bg-primary-1000"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

function DocumentViewerModal({
  src,
  open,
  onHide,
}: {
  open: boolean;
  src: string;
  onHide: (open: boolean | any) => void;
}) {
  return (
    <Modal setOpen={onHide} open={open}>
      <iframe src={src} className="h-[80vh] w-[80vw]" />
    </Modal>
  );
}

interface EnvelopTableProps extends Omit<DocumentsTableProps, "data"> {
  data: Envelope[];
}

function EnvelopTable({ data, ...props }: EnvelopTableProps) {
  return (
    <div className="px-3">
      {data.map((envelope) => (
        <div key={envelope.id}>
          <Disclosure>
            <Disclosure.Button className="py-2">
              {envelope.name}
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500">
              <DocumentsTable {...props} data={envelope.documentList} />
            </Disclosure.Panel>
          </Disclosure>
        </div>
      ))}
    </div>
  );
}

function DocumentCategoryBlock({
  category,
  children,
}: {
  category: string;
  children?: React.ReactNode;
}) {
  // States
  const [isOpen, setIsOpen] = useState<boolean>(true);

  // Hooks
  const { i18n } = useTranslation();
  return (
    <div className={"mb-9"}>
      <div
        className="mb-4 flex cursor-pointer items-center gap-x-4 pl-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typo
          type="wlc_inter_m"
          children={
            i18n.exists(`page.profile.documents.${category?.toLowerCase()}`)
              ? `page.profile.documents.${category?.toLowerCase()}`
              : category
          }
          className="font-semibold"
        />

        <Icon type={"wc2_chevronRight"} rotate={isOpen ? 0 : 90} />
      </div>
      <div
        style={{
          transition: `0.5s`,
          ...(isOpen
            ? { maxHeight: `1000px`, overflow: `auto` }
            : { maxHeight: `0`, overflow: `hidden` }),
        }}
      >
        {children}
      </div>
    </div>
  );
}
